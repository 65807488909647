import { j as u } from "./jsx-runtime-B6kdoens.js";
import { $ as x } from "./index-28E3tTLh.js";
import * as y from "react";
import { c as g } from "./utils-CJ9afRe1.js";
import { c as j } from "./index-BV2fynjD.js";
const v = j("flex", {
  variants: {
    divider: {
      true: "divide-y divide-gray-neutral-80"
    },
    wrap: {
      true: "flex-wrap"
    },
    row: {
      true: "flex-row"
    },
    rowReverse: {
      true: "flex-row-reverse"
    },
    col: {
      true: "flex-col"
    },
    space: {
      none: "gap-0",
      xs: "gap-1",
      sm: "gap-2",
      default: "gap-3",
      md: "gap-4",
      lg: "gap-6",
      xl: "gap-8",
      xxl: "gap-12",
      xxxl: "gap-16"
    },
    items: {
      center: "items-center",
      start: "items-start",
      default: "",
      end: "items-end",
      stretch: "items-stretch",
      baseline: "items-baseline"
    },
    justify: {
      start: "justify-start",
      end: "justify-end",
      center: "justify-center",
      between: "justify-between",
      around: "justify-around",
      evenly: "justify-evenly"
    },
    defaultVariants: {
      row: !1,
      wrap: !1,
      rowReverse: !1,
      col: !0,
      items: "",
      space: "default",
      justify: "start",
      divider: !1
    }
  }
}), w = y.forwardRef(({ as: a, children: r, wrap: s, rowReverse: e, divider: i, items: c, justify: f, asChild: o, space: l, row: t, className: n, ...p }, d) => {
  const m = o ? x : a || "div";
  return /* @__PURE__ */ u.jsx(
    m,
    {
      ref: d,
      className: g(v({ row: t, items: c, wrap: s, rowReverse: e, col: !t && !e, space: l, className: n, divider: i, justify: f })),
      ...p,
      children: r
    }
  );
});
w.displayName = "Stack";
export {
  w as S
};
