import { createContext as v, useMemo as $, createElement as b, useContext as h, useLayoutEffect as P } from "react";
function g(n, r) {
  const o = /* @__PURE__ */ v(r);
  function a(c) {
    const { children: e, ...t } = c, s = $(
      () => t,
      Object.values(t)
    );
    return /* @__PURE__ */ b(o.Provider, {
      value: s
    }, e);
  }
  function u(c) {
    const e = h(o);
    if (e) return e;
    if (r !== void 0) return r;
    throw new Error(`\`${c}\` must be used within \`${n}\``);
  }
  return a.displayName = n + "Provider", [
    a,
    u
  ];
}
function C(n, r = []) {
  let o = [];
  function a(c, e) {
    const t = /* @__PURE__ */ v(e), s = o.length;
    o = [
      ...o,
      e
    ];
    function l(d) {
      const { scope: i, children: p, ...f } = d, m = (i == null ? void 0 : i[n][s]) || t, S = $(
        () => f,
        Object.values(f)
      );
      return /* @__PURE__ */ b(m.Provider, {
        value: S
      }, p);
    }
    function x(d, i) {
      const p = (i == null ? void 0 : i[n][s]) || t, f = h(p);
      if (f) return f;
      if (e !== void 0) return e;
      throw new Error(`\`${d}\` must be used within \`${c}\``);
    }
    return l.displayName = c + "Provider", [
      l,
      x
    ];
  }
  const u = () => {
    const c = o.map((e) => /* @__PURE__ */ v(e));
    return function(t) {
      const s = (t == null ? void 0 : t[n]) || c;
      return $(
        () => ({
          [`__scope${n}`]: {
            ...t,
            [n]: s
          }
        }),
        [
          t,
          s
        ]
      );
    };
  };
  return u.scopeName = n, [
    a,
    w(u, ...r)
  ];
}
function w(...n) {
  const r = n[0];
  if (n.length === 1) return r;
  const o = () => {
    const a = n.map(
      (u) => ({
        useScope: u(),
        scopeName: u.scopeName
      })
    );
    return function(c) {
      const e = a.reduce((t, { useScope: s, scopeName: l }) => {
        const d = s(c)[`__scope${l}`];
        return {
          ...t,
          ...d
        };
      }, {});
      return $(
        () => ({
          [`__scope${r.scopeName}`]: e
        }),
        [
          e
        ]
      );
    };
  };
  return o.scopeName = r.scopeName, o;
}
const E = globalThis != null && globalThis.document ? P : () => {
};
export {
  C as $,
  E as a,
  g as b
};
