import * as e from "react";
import { a as r } from "./index-Cp-Nu9_j.js";
const n = e.useId || (() => {
});
let f = 0;
function i($) {
  const [a, o] = e.useState(n());
  return r(() => {
    o(
      (t) => t ?? String(f++)
    );
  }, [
    $
  ]), a ? `radix-${a}` : "";
}
export {
  i as $
};
